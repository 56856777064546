import {Component, Input, OnInit, ElementRef} from '@angular/core';
import * as d3Select from 'd3-selection';

import {MetricConfig} from '../../iaq.model';

@Component({
  selector: 'app-iaq-metric-color-bar',
  templateUrl: './iaq-metric-color-bar.component.html',
  styleUrls: ['./iaq-metric-color-bar.component.scss']
})
export class IaqMetricColorBarComponent implements OnInit {
  @Input() metric: Record<string, any> = {};
  @Input() metricValue = 0;

  private svg: any;
  public lowValue: any;
  public highValue: any;

  goodClrCode = '#138A36';
  moderateClrCode = 'rgba(26, 26, 26, 0.5)';
  criticalClrCode = 'rgba(0, 0, 0, 0.25)';
  finalClrCode = 'rgba(151, 151, 151, 0.20)';
  actualValueClr = '';

  goodIaqConfig: MetricConfig = {range: [{from: 0, to: 0}]};
  moderateIaqConfig: MetricConfig = {range: [{from: 0, to: 0}]};
  criticalIaqConfig: MetricConfig = {range: [{from: 0, to: 0}]};
  unit = '';
  lowDisplayValue = 0;
  highDisplayValue  = 0;
  metricValuePosition = 0;
  formattedMetricValue: string | number = 0;

  constructor(private container: ElementRef) {}

  ngOnInit(): void {
    this.goodIaqConfig = this.metric.config.good;
    this.moderateIaqConfig = this.metric.config.moderate;
    this.criticalIaqConfig = this.metric.config.critical;
    this.unit = this.metric.config.unit;
    this.getColorRange();
  }

  public buildSvg(): void{
    this.svg = d3Select.select(this.container.nativeElement).select('.colorBar').attr('width', '100%');
    this.svg.attr('height', 70);
  }

  public createRects(colorValues: any): any{
    const colorOffsets: any[] = [];
    colorValues.forEach((element: any) => {
      colorOffsets.push({
        offset: element.value + '%',
        color: element.color
      });
    });

    // Append a defs (for definition) element to your SVG
    const defs = this.svg.append('defs');

    // Append a linearGradient element to the defs and give it a unique id
    const linearGradient = defs.append('linearGradient')
      .attr('id', `linear-gradient_${this.metricValue}`);

    // Append multiple color stops by using D3's data/enter step
    linearGradient.selectAll('stop')
      .data(colorOffsets)
      .enter().append('stop')
      .attr('offset', (d: any) => d.offset)
      .attr('stop-color', (d: any) => d.color);

    this.svg.append('rect')
      .attr('width', '100%')
      .attr('height', 30)
      .attr('ry', 5)
      .attr('rx', 5)
      .attr('x', 0)
      .attr('y', 40)
      .attr('fill', `url(#linear-gradient_${this.metricValue}`);

    this.svg.append('center');

    const focus = this.svg.append('g')
      .attr('class', 'focus')
      .style('display', 'block');

    // Actual value
    focus.append('text')
      .attr('y', 28)
      .attr('x', `${this.metricValuePosition}%`)
      .text(this.formattedMetricValue)
      .style('font-size', 18)
      .style('font-weight', 600)
      .style('text-anchor', 'middle')
      .style('transform', () => {
        if (this.metricValuePosition >= 97){
          const translate = this.metricValue.toString().length * 4;
          return `translate(-${translate}px, 0px)`;
        }
        else {
          return 'translate(4px, 0px)';
        }
      });

    // Arrow indicator
    focus.append('svg')
      .attr('y', 35)
      .attr('x', this.metricValuePosition >= 97 ? '98%' : `${this.metricValuePosition}%`)
      .style('stroke', 'white')
      .style('stroke-width', '1px')
      .style('transform', () => {
        if (this.metricValuePosition >= 97){
          const translate = this.metricValue.toString().length * 4;
          return `translate(-${translate}px, 0px)`;
        }
        else {
          return 'translate(4px, 0px)';
        }
      })
      .append('path')
      .attr('d', 'M6.83,11,8.54,3.55A2.08,2.08,0,0,0,6.51,1H3.09a2.09,2.09,0,0,0-2,2.55L2.77,11A2.08,2.08,0,0,0,6.83,11Z');

    // Good text indicator
    const goodPart = colorValues.find((el: {value: number; color: string}) => el.color === this.goodClrCode);
    focus.append('text')
      .attr('y', 65)
      .attr('x', `${goodPart.value}%`)
      .text('Good')
      .style('font-size', 12)
      .style('fill', '#FFF')
      .style('transform', () => {
        if (goodPart.value === 0){
          return `translate(5px, 0px)`;
        }
        else {
          return 'translate(0px, 0px)';
        }
      });
  }

  getColorRange(): any{
    const goodRange = this.goodIaqConfig.range.map(range => ({...range, category: 'good'}));
    const moderateRange = this.moderateIaqConfig.range.map(range => ({...range, category: 'moderate'}));
    const criticalRange = this.criticalIaqConfig.range.map(range => ({...range, category: 'critical'}));

    const allRanges = [...goodRange, ...moderateRange, ...criticalRange];

    const sortedRange = allRanges.sort((a, b) => a.from - b.from);

    this.lowDisplayValue = sortedRange[0].from;
    this.highDisplayValue = sortedRange[sortedRange.length - 1].to || 0;

    this.metricValuePosition = Math.round(this.metricValue / this.highDisplayValue  * 100);
    if (this.metricValue < this.lowDisplayValue) {
      this.metricValuePosition = 0;
    }

    if (this.metricValue > this.highDisplayValue) {
      this.metricValuePosition = 100;
    }

    this.formattedMetricValue = this.metric.name === 'temperature' ? Number(this.metricValue).toFixed(1) : Math.round(this.metricValue);

    let actualHighValue = 0;

    if (this.lowDisplayValue > 0){
      this.lowValue = 0;
      actualHighValue = this.highDisplayValue;
      this.highDisplayValue = this.metricValue > this.highDisplayValue ? (Math.round(this.metricValue) + 3) : this.highDisplayValue;
      this.highValue = this.highDisplayValue - this.lowDisplayValue;
    }else{
      this.lowValue = this.lowDisplayValue;
      actualHighValue = this.highDisplayValue;
      this.highValue = this.metricValue > this.highDisplayValue ? this.metricValue : this.highDisplayValue;
    }

    let highClr = '';

    const testValue: any[] = [];

    allRanges.forEach((item) => {
      const color = this.getLinearColor(item.category);
      if (item.from === this.lowValue){
        testValue.push({ value: 0, color});
      }else{
        const val = this.lowDisplayValue > 0 ?
          ((item.from - this.lowDisplayValue) / (this.highValue) * 100) : (item.from / (this.highValue) * 100);
        testValue.push({ value: val, color});
      }
      if (item.to === actualHighValue){
        testValue.push({ value: 100, color: this.finalClrCode });
        highClr = this.goodClrCode;
        // @ts-ignore
      }else if (actualHighValue > item.to){
        highClr = this.goodClrCode;
      }
      // @ts-ignore
      if (item.from <= this.metricValue  && this.metricValue <= item.to){
        this.actualValueClr = this.goodClrCode;
        this.metricValuePosition = this.lowDisplayValue > 0 ?
          (((this.metricValue - this.lowDisplayValue) / this.highValue) * 100) : ((this.metricValue / this.highValue) * 100);
      }
    });

    if (this.metricValuePosition === undefined){
      this.actualValueClr = highClr;
      this.metricValuePosition = this.lowDisplayValue > 0 ?
        (((this.metricValue - this.lowDisplayValue) / this.highValue) * 100) : ((this.metricValue / this.highValue) * 100);

      if (this.metricValue > this.highDisplayValue){
        this.highDisplayValue = this.highValue;
      }
    }

    testValue.sort((a, b) => a.value - b.value);

    this.buildSvg();
    this.createRects(testValue);
  }

  getLinearColor(category: string): string {
    switch (category) {
      case 'moderate':
        return this.moderateClrCode;
      case 'critical':
        return this.criticalClrCode;
      default:
        return this.goodClrCode;
    }
  }

}
