import {Component, OnInit} from '@angular/core';
import {delay} from 'rxjs/operators';

import {SpinnerService} from './httpservice/spinner/spinner.service';
import {IaqService} from './iaq.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'iaq';
  loading = false;

  constructor(
    public spinnerService: SpinnerService,
    private iaqService: IaqService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.listenToLoading();
    this.getAuthentication();
  }

  getAuthentication(): any {
    const url = window.location.href;
    const [, token] = url.split('token=');

    if (token) {
      this.router.navigate(['/summary']);
    } else {
      this.navigateToUnauthorizedScreen();
    }
    this.spinnerService.setLoading(false, 'unauthorized');
  }

  navigateToUnauthorizedScreen(): void {
    this.spinnerService.setLoading(false, 'unauthorized');
    this.router.navigate(['/unauthorized']);
  }

  /**
   * Listen to the isLoading property in the SpinnerService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this.spinnerService.isLoading
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

}
