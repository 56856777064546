import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import { SpinnerService } from './spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

  constructor(public spinnerService: SpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.spinnerService.isLoading.next(true);

    this.spinnerService.setLoading(true, req.url);
    return next.handle(req)
      .pipe(catchError((err) => {
        this.spinnerService.setLoading(false, req.url);
        return err;
      }))
      .pipe(map<unknown, any>((evt: unknown) => {
        if (evt instanceof HttpResponse) {
          this.spinnerService.setLoading(false, req.url);
        }
        return evt;
      }));
  }
}
