import {Injectable} from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class IaqConstants {
  public APIUrl = 'api/webapp/';
  public signingUrl = 'signingHostBuildingUrl';

  public APIIAQ = environment.indoorAirQualityUrl + this.APIUrl;

  public APIBuilding = 'building/';

  public iaqData = this.APIIAQ + 'indoor-outdooraqi';
  public buildingConfigData = this.APIIAQ + this.APIBuilding + 'config';
  public latestIaqValues = this.APIIAQ + 'dashboard/latest';
  public buildingHours = this.APIIAQ + 'buildinghours';
}
