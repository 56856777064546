import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-iaq-metric-card',
  templateUrl: './iaq-metric-card.component.html',
  styleUrls: ['./iaq-metric-card.component.scss']
})
export class IaqMetricCardComponent implements OnInit {

  @Input() metric: Record<string, any> = {};
  @Input() metricValue: number = 0;
  @Input() lastUpdated?: string = '';
  @Input() isSummaryCardMetric?: boolean = false;
  flipped: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  flipIt() {
    this.flipped = !this.flipped;
  }

  getCardTitle(): string {
    let title = '';
    switch (this.metric.name) {
      case 'co2':
        title = 'Carbon Dioxide';
        break;
      case 'temperature':
        title = 'Temperature';
        break;
      case 'humidity':
        title = 'Humidity';
        break;
      case 'tvoc':
        title = 'Tvoc';
        break;
      case 'pm25':
        title = 'Particulate Matter 2.5';
        break;
      case 'pm10':
        title = 'Particulate Matter 10.0';
        break;
      case 'co':
        title = 'Carbon Monoxide';
        break;
      case 'noise':
        title = 'Noise';
        break;
      case 'light':
        title = 'Light';
        break;
      default: title = '';
    }

    return title;
  }

}
