import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-iaq-card',
  templateUrl: './iaq-card.component.html',
  styleUrls: ['./iaq-card.component.scss']
})
export class IaqCardComponent implements OnInit {
  @Input() indoorIAQStatus: string;

  constructor() {
    this.indoorIAQStatus = '';
  }

  ngOnInit(): void {
  }

  getImageUrl(): string {
    return `assets/images/${this.indoorIAQStatus.toLowerCase()}_quality_air.png`;
  }

}
