<div class='card-container'>
  <div class='flippable-card' [ngClass]="{'flipped':flipped}" >
    <mat-card class='iaq-metric-card-container'>
      <mat-card-title class="iaq-metric-card-title">{{getCardTitle()}}
        <button *ngIf="!flipped" mat-icon-button aria-label="Show description" (click)="flipIt()">
          <mat-icon class="iaq-metric-card-icon">info</mat-icon>
        </button>
      </mat-card-title>
      <mat-card-subtitle *ngIf="isSummaryCardMetric">Last updated @ {{lastUpdated}}</mat-card-subtitle>
      <mat-card-content>
        <app-iaq-metric-color-bar *ngIf="!flipped" [metric]="metric" [metricValue]="metricValue"></app-iaq-metric-color-bar>
        <a class="iaq-metric-card-link" *ngIf="isSummaryCardMetric" routerLink="/detail" routerLinkActive="active">View More Metrics</a>
      </mat-card-content>
    </mat-card>
    <mat-card class='iaq-metric-card-container back'>
      <mat-card-title class="iaq-metric-card-title">{{getCardTitle()}}
        <button mat-icon-button aria-label="Show description" (click)="flipIt()">
          <mat-icon class="iaq-metric-card-icon">info</mat-icon>
        </button>
      </mat-card-title>
      <mat-card-content>
        <div [innerHTML]="metric.config.info" class="iaq-metric-card-description"></div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
