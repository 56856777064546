import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';

import {IaqService} from '../../iaq.service';
import {LatestIaqValues} from '../../iaq.model';

@Component({
  selector: 'app-iaq-summary-screen',
  templateUrl: './iaq-summary-screen.component.html',
  styleUrls: ['./iaq-summary-screen.component.scss']
})
export class IaqSummaryScreenComponent implements OnInit, OnDestroy {
  indoorIAQStatus = '';
  indoorPollutantIndex = 0;
  outdoorPollutantIndex = 0;
  configData: Record<string, any> = {};
  co2ConfigData: Record<string, any> = {};
  co2Value = 0;
  iaqValues: LatestIaqValues = {};
  latestIAQValuesTimer = 0;
  lastUpdated = '';
  buildingStartHour = '';
  buildingEndHour = '';
  isOpened = true;
  // TODO: Remove hardcoded value when Authentication is implemented.
  buildingParams = '?code=150NR';

  constructor(
    private iaqService: IaqService
  ) {}

  ngOnInit(): void {
    this.getBuildingHours();
  }

  getBuildingData(): void {
    if (this.isBuildingOpen()) {
      this.getIndoorOutdoorIaqData();
      this.getMetricsData();
      this.getLatestIaqValues();
      this.getRecursiveData();
    }
  }

  getRecursiveData(): void {
    // Get values every 5 minutes
    this.latestIAQValuesTimer = setInterval(() => {
      this.isBuildingOpen() ? this.getLatestIaqValues() : clearInterval(this.latestIAQValuesTimer);
    }, 5 * 60 * 1000);
  }

  getIndoorOutdoorIaqData(): void {
    this.iaqService.fetchIndoorOutdoorIaqData(this.buildingParams).subscribe(indoorOutdoorIaqData => {
      this.indoorIAQStatus = indoorOutdoorIaqData?.indoorAQI.status || '';
      this.indoorPollutantIndex = Number(indoorOutdoorIaqData?.indoorAQI.pollutantIndex || 0 );
      this.outdoorPollutantIndex = Number(indoorOutdoorIaqData?.outdoorAQI.pollutantIndex || 0);
    });
  }

  getMetricsData(): void {
    this.iaqService.fetchMetricsData(this.buildingParams).subscribe(configData => {
      this.configData = configData;
      this.co2ConfigData = this.configData.metrics[0];
    });
  }

  getLatestIaqValues(): void {
    this.lastUpdated = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    this.iaqService.fetchLatestIaqValues(this.buildingParams).subscribe(iaqValues => {
      this.iaqValues = iaqValues;
      this.co2Value = Math.round(this.iaqValues.co2);
    });
  }

  getBuildingHours(): void {
    this.iaqService.fetchBuildingHours(this.buildingParams).subscribe(buildingHours => {
      this.buildingStartHour = buildingHours.startHour;
      this.buildingEndHour = buildingHours.endHour;
      this.getBuildingData();
    });
  }

  isBuildingOpen(): boolean {
    const startDate = moment.utc(this.buildingStartHour).format('LLLL');
    const endDate   = moment.utc(this.buildingEndHour).format('LLLL');
    const date  = momentTimeZone().tz('America/Chicago').format('LLLL');

    this.isOpened = moment(date).isBetween(startDate, endDate);
    return moment(date).isBetween(startDate, endDate);
  }

  ngOnDestroy(): void {
    clearInterval(this.latestIAQValuesTimer);
  }
}
