import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iaq-unauthorized-screen',
  templateUrl: './iaq-unauthorized-screen.component.html',
  styleUrls: ['./iaq-unauthorized-screen.component.scss']
})
export class IaqUnauthorizedScreenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
