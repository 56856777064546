import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {BuildingConfigData, IndoorOutdoorIaq, LatestIaqValues, BuildingHoursValues} from './iaq.model';
import {IaqConstants} from './iaq.constants';
import {HttpCallService} from './httpservice/httpCall/http.call.service';

@Injectable({
  providedIn: 'root'
})
export class IaqService {

  constructor(
    private httpService: HttpCallService,
    private iaqConstant: IaqConstants
  ) { }

  getAuthenticationSigningKey(params: string): Observable<any> {
    const apiURL = this.iaqConstant.signingUrl;
    return this.httpService.get<any>(apiURL + params);
  }

  fetchIndoorOutdoorIaqData(params: string): Observable<IndoorOutdoorIaq> {
    const apiURL = this.iaqConstant.iaqData;
    return this.httpService.get<IndoorOutdoorIaq>(apiURL + params);
  }

  fetchMetricsData(params: string): Observable<BuildingConfigData> {
    const apiUrl = this.iaqConstant.buildingConfigData;
    return this.httpService.get<any>(apiUrl + params);
  }

  fetchLatestIaqValues(params: string): Observable<LatestIaqValues> {
    const apiURL = this.iaqConstant.latestIaqValues;
    return this.httpService.get<LatestIaqValues>(apiURL + params);
  }

  fetchBuildingHours(params: string): Observable<BuildingHoursValues> {
    const apiURL = this.iaqConstant.buildingHours;
    return this.httpService.get<BuildingHoursValues>(apiURL + params);
  }
}
