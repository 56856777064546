<div *ngIf="isOpened; else elseBlock" [ngStyle]="{'height': '100%'}">
  <app-iaq-card [indoorIAQStatus]="indoorIAQStatus"></app-iaq-card>
  <app-iaq-metric-card *ngIf="iaqValues.co2" [metric]="co2ConfigData" [metricValue]="co2Value" [isSummaryCardMetric]="true" [lastUpdated]="lastUpdated"></app-iaq-metric-card>
  <app-iaq-comparison-card *ngIf="indoorPollutantIndex < outdoorPollutantIndex" [indoorPollutantIndex]="indoorPollutantIndex" [outdoorPollutantIndex]="outdoorPollutantIndex"></app-iaq-comparison-card>
  <div class="iaq-summary-logo-container">
    <a href="https://www.cohesionib.com/" target="_blank">
      <img alt="cohesion" src="assets/images/logo.png" class="iaq-summary-image">
    </a>
  </div>
</div>
<ng-template #elseBlock>
  <app-iaq-off-hours-card></app-iaq-off-hours-card>
</ng-template>
