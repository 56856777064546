import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-iaq-comparison-card',
  templateUrl: './iaq-comparison-card.component.html',
  styleUrls: ['./iaq-comparison-card.component.scss']
})
export class IaqComparisonCardComponent implements OnInit {

  @Input() indoorPollutantIndex: number;
  @Input() outdoorPollutantIndex: number;

  constructor() {
    this.indoorPollutantIndex = 0;
    this.outdoorPollutantIndex = 0;
  }

  ngOnInit(): void {
  }

  getComparison() {
    return Math.round(this.outdoorPollutantIndex / this.indoorPollutantIndex )
  }

}
