<div *ngIf="isOpened; else elseBlock" [ngStyle]="{'height': '100%'}">
  <button (click)="goToSummary()" class="iaq-detail-button" type="button" mat-raised-button title="Go back">
    <span>Back to Air Quality Summary</span>
  </button>
  <div  *ngFor="let metricData of metricsData">
    <app-iaq-metric-card *ngIf="iaqValues[metricData.name]" [metric]="metricData" [metricValue]="iaqValues[metricData.name]"></app-iaq-metric-card>
  </div>
</div>
<ng-template #elseBlock>
  <app-iaq-off-hours-card></app-iaq-off-hours-card>
</ng-template>

