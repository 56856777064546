import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';

import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class HttpCallService {

  constructor(private http: HttpClient) { }

  public get<T>(URL: string): Observable<T> {
    const expandedHeaders = this.prepareHeader(null);
    return this.http.get<T>(URL, expandedHeaders).pipe(catchError(this.handleError<T>()));
  }

  /**
   * Handle Http operation that failed.
   */
  private handleError<T>(): any {
    return (error: any): Observable<T> => throwError(error);
  }

  /**
   * @param  HttpHeaders|null - headers
   */
  private prepareHeader(headers: HttpHeaders | null, url: string = '', method: string = ''): object {
    headers = headers || new HttpHeaders();

    if (!(method.toLowerCase() === 'post' && url.indexOf('/media/') > 0)
      && !(method.toLowerCase() === 'put' && url.indexOf('/media/') > 0)
      && !(method.toLowerCase() === 'post' && url.indexOf('/document/') > 0)
      && !(method.toLowerCase() === 'put' && url.indexOf('/document/') > 0)
      && !(method.toLowerCase() === 'post' && url.indexOf('/space/import/validate') > 0)
      && !(method.toLowerCase() === 'post' && url.indexOf('/user/import/validate') > 0)
      && !(method.toLowerCase() === 'post' && url.indexOf('/workorder/attachment') > 0)
      && !(method.toLowerCase() === 'post' && url.indexOf('/visitor/import/validate') > 0)
      && !(method.toLowerCase() === 'post' && url.indexOf('/parkingspot/import/validate') > 0)) {
      headers = headers.set('Content-Type', 'application/json');
      headers = headers.set('Accept', 'application/json');
    }

    return {
      headers
    };
  }
}
