import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IaqSummaryScreenComponent } from './screens/iaq-summary-screen/iaq-summary-screen.component';
import { IaqDetailScreenComponent } from './screens/iaq-detail-screen/iaq-detail-screen.component';
import {IaqUnauthorizedScreenComponent} from './screens/iaq-unauthorized-screen/iaq-unauthorized-screen.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'summary', component: IaqSummaryScreenComponent },
  { path: 'detail', component: IaqDetailScreenComponent },
  { path: 'unauthorized', component: IaqUnauthorizedScreenComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
