import { Injectable } from '@angular/core';
import {
  HttpRequest, HttpHandler, HttpEvent,
  HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor() { }

  /**
   * Set authentication header and content type in request
   * @param  HttpRequest<any> request
   * @param  HttpHandler next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJBTlRJTExBIiwibmFtZSI6ImFudGlsbGEuY29oZXNpb25pYi5jb20iLCJpYXQiOjE2NzM0NzE2MjksImV4cCI6MTgzMTE1MTYyOX0.RjI6TomSN_Q0f8eUSHaArY6Fs2h_LRE9WmFpv5Zui14'
      },
    });

    const header = this.prepareHeader(request, request.headers);
    request = request.clone(header);

    return next.handle(request);
  }


  /**
   * set header content type in request
   * @param  HttpRequest<any> request
   * @param  HttpHeaders|null headers
   */
  private prepareHeader(request: HttpRequest<any>, headers: HttpHeaders | null): object {
    headers = headers || new HttpHeaders();

    // Don't add header in API which used for post file
    if (!(request.method.toLowerCase() === 'post' && request.url.indexOf('/media/') > 0)
      && !(request.method.toLowerCase() === 'put' && request.url.indexOf('/media/') > 0)
      && !(request.method.toLowerCase() === 'post' && request.url.indexOf('/document/') > 0)
      && !(request.method.toLowerCase() === 'put' && request.url.indexOf('/document/') > 0)
      && !(request.method.toLowerCase() === 'post' && request.url.indexOf('/space/import/validate') > 0)
      && !(request.method.toLowerCase() === 'post' && request.url.indexOf('/user/import/validate') > 0)
      && !(request.method.toLowerCase() === 'post' && request.url.indexOf('/visitor/import/validate') > 0)
      && !(request.method.toLowerCase() === 'post' && request.url.indexOf('/workorder/attachment') > 0)
      && !(request.method.toLowerCase() === 'post' && request.url.indexOf('/parkingspot/import/validate') > 0)) {
      headers = headers.set('Content-Type', 'application/json');
    }

    return {
      headers
    };
  }
}
