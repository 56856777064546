/**
 * Angular Libraries
 */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

/**
 * Custom Classes
 */
import { IaqConstants } from './iaq.constants';

/**
 * Angular components
 */
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

/**
 * Custom Components
 */
import { AppComponent } from './app.component';
import { IaqSummaryScreenComponent } from './screens/iaq-summary-screen/iaq-summary-screen.component';
import { IaqDetailScreenComponent } from './screens/iaq-detail-screen/iaq-detail-screen.component';
import { IaqCardComponent } from './components/iaq-card/iaq-card.component';
import { IaqComparisonCardComponent } from './components/iaq-comparison-card/iaq-comparison-card.component';
import { IaqMetricCardComponent } from './components/iaq-metric-card/iaq-metric-card.component';
import { IaqMetricColorBarComponent } from './components/iaq-metric-color-bar/iaq-metric-color-bar.component';
import { IaqOffHoursCardComponent } from './components/iaq-off-hours-card/iaq-off-hours-card.component';
import { IaqUnauthorizedScreenComponent } from './screens/iaq-unauthorized-screen/iaq-unauthorized-screen.component';

/**
 * Custom Services
 */
import {AuthInterceptorService} from './httpservice/auth/auth.interceptor.service';
import {HttpCallService} from './httpservice/httpCall/http.call.service';
import {SpinnerInterceptor} from './httpservice/spinner/spinner-interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
    IaqSummaryScreenComponent,
    IaqDetailScreenComponent,
    IaqCardComponent,
    IaqComparisonCardComponent,
    IaqMetricCardComponent,
    IaqMetricColorBarComponent,
    IaqOffHoursCardComponent,
    IaqUnauthorizedScreenComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  providers: [
    IaqConstants,
    HttpCallService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
