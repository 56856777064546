import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {IaqService} from '../../iaq.service';
import {LatestIaqValues, Metric} from '../../iaq.model';

import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';

@Component({
  selector: 'app-iaq-detail-screen',
  templateUrl: './iaq-detail-screen.component.html',
  styleUrls: ['./iaq-detail-screen.component.scss']
})
export class IaqDetailScreenComponent implements OnInit, OnDestroy {

  metricsData: Metric[] = [];
  iaqValues: LatestIaqValues = {};
  latestIAQValuesTimer = 0;
  buildingStartHour = '';
  buildingEndHour = '';
  isOpened = true;
  // TODO: Remove hardcoded value when Authentication is implemented.
  buildingParams = '?code=150NR';

  constructor(
    private router: Router,
    private iaqService: IaqService
  ) {}

  ngOnInit(): void {
    this.getBuildingHours();
  }

  goToSummary(): void {
    this.router.navigate(['/summary']);
  }

  getBuildingHours(): void {
    this.iaqService.fetchBuildingHours(this.buildingParams).subscribe(buildingHours => {
      this.buildingStartHour = buildingHours.startHour;
      this.buildingEndHour = buildingHours.endHour;
      this.getBuildingData();
    });
  }

  getBuildingData(): void {
    if (this.isBuildingOpen()) {
      this.getMetricsData();
      this.getLatestIaqValues();
      this.getRecursiveData();
    }
  }

  isBuildingOpen(): boolean {
    const startDate = moment.utc(this.buildingStartHour).format('LLLL');
    const endDate   = moment.utc(this.buildingEndHour).format('LLLL');
    const date  = momentTimeZone().tz('America/Chicago').format('LLLL');

    this.isOpened = moment(date).isBetween(startDate, endDate);
    return moment(date).isBetween(startDate, endDate);
  }

  // TODO: Is there a way to remove this duplicate code? (Already implemented in Summary Screen)
  getMetricsData(): void {
    this.iaqService.fetchMetricsData(this.buildingParams).subscribe(metricsData => {
      this.metricsData = metricsData.metrics.filter(metric => Object.keys(metric.config).length > 0);
    });
  }

  getLatestIaqValues(): void {
    this.iaqService.fetchLatestIaqValues(this.buildingParams).subscribe(iaqValues => {
      this.iaqValues = iaqValues;
    });
  }

  getRecursiveData(): void {
    // Get values every 5 minutes
    this.latestIAQValuesTimer = setInterval(() => this.getLatestIaqValues(), 5 * 60 * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.latestIAQValuesTimer);
  }

}
